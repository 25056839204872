export default function App() {
  return (
    <div class="flex flex-col justify-center font-mono dark">
      <p class="text-white text-center text-4xl"> Subnet Sheet
      </p>
      <div class="relative overflow-x-auto text-xl">
        <table class="w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-gray-700 uppercase bg-gray-50 border-b dark:bg-gray-800 dark:text-gray-400 dark:text-white">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Addresses
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Hosts
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Netmask
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Amount of a Class C
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        /30
                    </th>
                    <td class="px-6 py-4">
                        4
                    </td>
                    <td class="px-6 py-4">
                      2
                    </td>
                    <td class="px-6 py-4">
                      255.255.255.252
                    </td>
                    <td class="px-6 py-4">
                      1/64
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /29
                    </th>
                    <td class="px-6 py-4">
                      8
                    </td>
                    <td class="px-6 py-4">
                      6
                    </td>
                    <td class="px-6 py-4">
                      255.255.255.248
                    </td>
                    <td class="px-6 py-4">
                      1/32
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /28
                    </th>
                    <td class="px-6 py-4">
                      16
                    </td>
                    <td class="px-6 py-4">
                      32
                    </td>
                    <td class="px-6 py-4">
                      255.255.255.240
                    </td>
                    <td class="px-6 py-4">
                      1/16
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /27
                    </th>
                    <td class="px-6 py-4">
                      32
                    </td>
                    <td class="px-6 py-4">
                      30
                    </td>
                    <td class="px-6 py-4">
                      255.255.255.224
                    </td>
                    <td class="px-6 py-4">
                      1/8
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /26
                    </th>
                    <td class="px-6 py-4">
                      64
                    </td>
                    <td class="px-6 py-4">
                      62
                    </td>
                    <td class="px-6 py-4">
                      255.255.255.192
                    </td>
                    <td class="px-6 py-4">
                      1/4
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /25
                    </th>
                    <td class="px-6 py-4">
                      128
                    </td>
                    <td class="px-6 py-4">
                      126
                    </td>
                    <td class="px-6 py-4">
                      255.255.255.128
                    </td>
                    <td class="px-6 py-4">
                      1/2
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /24
                    </th>
                    <td class="px-6 py-4">
                      256
                    </td>
                    <td class="px-6 py-4">
                      254
                    </td>
                    <td class="px-6 py-4">
                      255.255.255.0
                    </td>
                    <td class="px-6 py-4">
                      1
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /23
                    </th>
                    <td class="px-6 py-4">
                      512
                    </td>
                    <td class="px-6 py-4">
                      510
                    </td>
                    <td class="px-6 py-4">
                      255.255.254.0
                    </td>
                    <td class="px-6 py-4">
                      2
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /22
                    </th>
                    <td class="px-6 py-4">
                      1024
                    </td>
                    <td class="px-6 py-4">
                      1022
                    </td>
                    <td class="px-6 py-4">
                      255.255.252.0
                    </td>
                    <td class="px-6 py-4">
                      4
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /21
                    </th>
                    <td class="px-6 py-4">
                      2048
                    </td>
                    <td class="px-6 py-4">
                      2046
                    </td>
                    <td class="px-6 py-4">
                      255.255.248.0
                    </td>
                    <td class="px-6 py-4">
                      8
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /20
                    </th>
                    <td class="px-6 py-4">
                      4096
                    </td>
                    <td class="px-6 py-4">
                      4094
                    </td>
                    <td class="px-6 py-4">
                      255.255.240.0
                    </td>
                    <td class="px-6 py-4">
                      16
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /19
                    </th>
                    <td class="px-6 py-4">
                      8192
                    </td>
                    <td class="px-6 py-4">
                      8190
                    </td>
                    <td class="px-6 py-4">
                      255.255.224.0
                    </td>
                    <td class="px-6 py-4">
                      32
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /18
                    </th>
                    <td class="px-6 py-4">
                      16384
                    </td>
                    <td class="px-6 py-4">
                      16382
                    </td>
                    <td class="px-6 py-4">
                      255.255.192.0
                    </td>
                    <td class="px-6 py-4">
                      64
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /17
                    </th>
                    <td class="px-6 py-4">
                      32768
                    </td>
                    <td class="px-6 py-4">
                      32766
                    </td>
                    <td class="px-6 py-4">
                      255.255.128.0
                    </td>
                    <td class="px-6 py-4">
                      128
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      /16
                    </th>
                    <td class="px-6 py-4">
                      65536
                    </td>
                    <td class="px-6 py-4">
                      65534
                    </td>
                    <td class="px-6 py-4">
                      255.255.0.0
                    </td>
                    <td class="px-6 py-4">
                      256
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
  )
}